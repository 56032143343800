.textcontent{
    margin: 2rem 10%;
}

.infoSection{
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.navigateIcon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    text-align: center;
}