.header{
    text-align: center;
    margin: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header img{
    padding:10px;
    width: 30%;
}
.header img:hover{
    box-shadow: 0 0 200px rgba(33,33,33,.2);
    border-radius: 0.5rem;
    cursor: pointer;
}
