.navbar{
    bottom: 0;
    position: fixed;
    background-color: white;
    padding: 10px 0;
    z-index: 4;
}

@media only screen and (min-width: 800px) {
    .navbar {
        width:40%;
    }
}

@media only screen and (max-width: 800px) {
    .navbar {
        width:100%;
    }
}